import { Link } from '@reach/router'
import React from 'react'
import Layout from '../components/layout'


const NotFoundPage = () => {
  return (
    <Layout>
        <div className="text-center text-5xl p-16">
          <p className="text-orange-light">404 Not Found</p>
          <Link to="/" className="text-blue-500">Go Back To Home Page</Link>
        </div>
    </Layout>
  )
}

export default NotFoundPage